import {BaseRequest} from "@/request/BaseRequest";

class ToolWxMess extends BaseRequest {

    public requestPath: string = '/store_tool/wxMess';

    /**
     * 微信活动消息推送
     * @param data
     */
    activityPush(data: any): any {
        return this.post(`${this.requestPath}/activityPush`, data);
    }

    /**
     * 优惠券消息推送
     * @param data
     */
    couponsPush(data: any): any {
        return this.post(`${this.requestPath}/couponsPush`, data);
    }

    /**
     * 商品消息推送
     * @param data
     */
    goodsPush(data: any): any {
        return this.post(`${this.requestPath}/goodsPush`, data);
    }

}

const c = new ToolWxMess();
export {c as ToolWxMess};