




































































































































import {ToolWxMess} from "@/request/store/ToolWxMess";
import {MemberSet} from "@/request/customer/MemberSet";
import {ActivityInfo} from "@/request/marketing/ActivityInfo";
import {Goods} from "@/request/goods/Goods";
import {CouponsSet} from "@/request/marketing/CouponsSet";

import {Vue, Component, Watch} from "vue-property-decorator";

@Component({
    name: 'store_tool_wxMess'
})
export default class store_tool_wxMess extends Vue {
    modal: any = ToolWxMess;
    search: Array<any> = [
        {
            type: 'Select',
            label: '消息类型',
            key: 'funcType',
            width: '160px',
            clearable: true,
            items: [
                {
                    label: '新商品消息',
                    value: 1
                },
                {
                    label: '活动推广消息',
                    value: 2
                },
                {
                    label: '优惠券发放通知消息',
                    value: 3
                }
            ]
        },
        {
            type: 'Select',
            label: '状态',
            key: 'status',
            width: '120px',
            clearable: true,
            items: [
                {
                    label: '推送中',
                    value: 0
                },
                {
                    label: '推送成功',
                    value: 1
                },
                {
                    label: '推送失败',
                    value: 2
                }
            ]
        },
        {
            type: 'DatePicker',
            pickerType: 'datetimerange',
            format: 'yyyy-MM-dd HH:mm',
            label: '发送时间',
            width: '220px',
            placeholder: '请选择发送时间'
        }
    ];
    type: number = 0;
    memberList: Array<any> = [];
    activityList: Array<any> = [];
    goodsList: Array<any> = [];
    couponList: Array<any> = [];
    remoteLoading: boolean = false;
    formData: any = {
        targetId: '',
        distance: 3,
        memberLv: 0,
        remind: '',
        content: '',
        startTime: '',
        endTime: ''
    };
    formRules: any = {
        targetId: {required: true, trigger: 'blur'},
        startTime: {required: true, trigger: 'blur'},
        endTime: {required: true, trigger: 'blur'},
        remind: {required: true, trigger: 'blur'},
        content: {required: true, trigger: 'blur'}
    };
    columns: Array<any> = [
        {
            title: '目标人群',
            key: 'userMemberName',
            minWidth: 120
        },
        {
            title: '类型',
            key: 'funcTypeName',
            minWidth: 120
        },
        {
            title: '状态',
            key: 'statusStr',
            minWidth: 100
        },
        {
            title: '目标名字',
            key: 'targetName',
            minWidth: 120
        },
        {
            title: '提醒信息',
            key: 'remind',
            minWidth: 120
        },
        {
            title: '消息内容',
            key: 'content',
            minWidth: 120,
            tooltip: true
        },
        {
            title: '开始时间',
            key: 'startTime',
            minWidth: 180
        },
        {
            title: '结束时间',
            key: 'endTime',
            minWidth: 180
        },
        {
            title: '发送时间',
            key: 'createTime',
            minWidth: 180
        }
    ];

    created() {
        MemberSet.getAll().then((body: any) => {
            this.memberList = body.data || [];
        });
        ActivityInfo.getAll().then((body: any) => {
            this.activityList = body.data || [];
        });
        Goods.getAll().then((body: any) => {
            this.goodsList = body.data || [];
        });
        CouponsSet.getAll().then((body: any) => {
            this.couponList = body.data || [];
        })
    }

    @Watch('type')
    typeChange() {
        this.formData.targetId = '';
    }

    createNew() {

    }

    submit() {
        let self: any = this;
        self.$refs['el-form'].validate((val: boolean) => {
            if (val) {
                self.$refs['el-table'].changeSubmitLoading();
                switch (this.type) {
                    case 0: //活动
                        ToolWxMess.activityPush(this.formData).then((body: any) => {
                            this.onSuccessTip(body);
                        });
                        break;
                    case 1: //优惠券
                        ToolWxMess.couponsPush(this.formData).then((body: any) => {
                            this.onSuccessTip(body);
                        });
                        break;
                    case 2: //商品
                        ToolWxMess.goodsPush(this.formData).then((body: any) => {
                            this.onSuccessTip(body);
                        });
                        break;
                }
            }
        })
    }

    activityRemoteSearch(query: string) {
        this.remoteLoading = true;
        ActivityInfo.getAll(`?name=${query}`).then((body: any) => {
            this.remoteLoading = false;
            this.activityList = body.data || [];
        })
    }

    couponRemoteSearch(query: string) {
        this.remoteLoading = true;
        CouponsSet.getAll(`?name=${query}`).then((body: any) => {
            this.remoteLoading = false;
            this.couponList = body.data || [];
        })
    }

    goodRemoteSearch(query: string) {
        this.remoteLoading = true;
        Goods.getAll(`?name=${query}`).then((body: any) => {
            this.remoteLoading = false;
            this.goodsList = body.data || [];
        })
    }

    onSuccessTip(body: any) {
        let self: any = this;
        let table = self.$refs['el-table'];
        table.changeSubmitLoading();
        if (body.code === 0) {
            this.$Message.info('发送成功');
            table.hideDrawer();
            table.flush();
        }
    }

}

